import React, { useEffect, useState } from 'react'
import Seo from '../components/Seo'
import Footer from '../components/Footer'
import { graphql } from 'gatsby'
import linkResolver from '../utils/linkResolver'
import htmlSerializer from '../utils/htmlSerializer'
import { IssueArchive } from '../components/IssueArchive'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { useLocalState } from '../components/Layout'
import { useTransitionClasses } from '../hooks/useTransitionClasses'

const ALL_ISSUE_QUERY = graphql`
	query ALL_ISSUE_QUERY {
    prismicMetadata {
      _previewable
			data {
        splash_content {
          html
        }
			}
		}
		allPrismicIssue(
			sort: { order: DESC, fields: data___issue_number___text }
		) {
			nodes {
        _previewable
				id
				uid
        type
				data {
					editors_foreword {
						html
						text
            raw
					}
					issue_number {
						text
					}
					issue_title {
						text
					}
					featured_image {
            alt
            gatsbyImageData
					}
					articles {
						article {
							uid
              type
							document {
								... on PrismicEssay {
									id
									uid
									data {
										excerpt {
											text
										}
										title {
											text
										}
										featured_image {
                      alt
                      gatsbyImageData
										}
									}
									type
								}
								... on PrismicInterview {
									id
									uid
									type
									data {
										excerpt {
											text
										}
										title {
											text
										}
										featured_image {
                      alt
                      gatsbyImageData
										}
									}
								}
								... on PrismicPhotoStory {
									id
									uid
									type
									data {
										excerpt {
											text
										}
										title {
											text
										}
										featured_image {
                      alt
                      gatsbyImageData
										}
									}
								}
                ... on PrismicPoem {
									id
									uid
									data {
										excerpt {
											text
										}
										title {
											text
										}
										featured_image {
                      alt
                      gatsbyImageData
										}
									}
									type
								}
							}
						}
					}
				}
			}
		}
	}
`

const IndexPage = ({ data, isPrismicPreview, transitionStatus }) => {
	const { requestedIssue, issuesLoaded, setIssuesLoaded, previewIssue, previewMetadata, setPreviewMetadata, scrollToIssue } = useLocalState(),
    issues = previewIssue
      ? [previewIssue, ...data.allPrismicIssue.nodes]
      : data.allPrismicIssue.nodes,
    [displayedIssues, setDisplayedIssues] = useState([...issues.slice(0, issuesLoaded)]),
    undisplayedIssuesExist = issues.length - displayedIssues.length > 0,
    increaseDisplayedIssueCount = () => {
      if(!undisplayedIssuesExist) return
      setDisplayedIssues([...issues.slice(0, displayedIssues.length + 1)])
      setIssuesLoaded(displayedIssues.length + 1)
      scrollToIssue(issues[displayedIssues.length]?.uid)
    },
    transitionClasses = useTransitionClasses({ transitionStatus })

  useEffect(() => {
    const requestedIssueIndex = issues.findIndex(issue => issue.uid === requestedIssue)

    if(displayedIssues.length < requestedIssueIndex + 1) {
      setDisplayedIssues([...issues.slice(0, requestedIssueIndex + 1)])
      setIssuesLoaded(requestedIssueIndex + 1)
    }
  }, [requestedIssue])

  // Check if we are previewing metadata
  useEffect(() => {
    if(previewMetadata?.data?.splash_content?.html !== data?.prismicMetadata?.data?.splash_content?.html) setPreviewMetadata(true) //setPreviewMetadata(data?.prismicMetadata?.data?.splash_content)
  }, [isPrismicPreview])

	return (
		<div className={transitionClasses}>
			<Seo />
			<div className="large-container pt-24 lg:pt-48 mb-48">
				{displayedIssues.map((issue) => <IssueArchive key={issue.id} issue={issue} /> )}
        {undisplayedIssuesExist && (
          <div className="mx-auto text-center">
            <a
              className="button cursor-pointer"
              onClick={increaseDisplayedIssueCount}
            >Load next issue</a>
          </div>
        )}
			</div>
      <Footer />
		</div>
	)
}

// export default IndexPage

export default withPrismicPreview(IndexPage, [{
  repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY,
  linkResolver,
  htmlSerializer
}])

export { ALL_ISSUE_QUERY }