import React from 'react'
import TableOfContents from './TableOfContents'
import TransitionLink from 'gatsby-plugin-transition-link'
import { pageTransitionIn, pageTransitionOut } from '../utils/pageTransition'
import { RichText } from 'prismic-reactjs'
import linkResolver from '../utils/linkResolver'
import htmlSerializer from '../utils/htmlSerializer'

const IssueArchive = ({ issue }) => {
	const { editors_foreword, issue_number, issue_title } =
		issue?.data,
    issueUrl = linkResolver(issue),
    articles = issue?.data?.articles

	return (
		<div id={`issue-${issue?.uid}`} className="pt-48 -mt-48">
      {/* Padding provides space when navigating to id */}
			<TransitionLink
        className="outline-none lg:outline-initial"
				to={issueUrl ?? ``}
				exit={pageTransitionOut}
				entry={pageTransitionIn}
			>
				<div className="small-container relative w-auto -mb-8">
					<h5 className="flex items-baseline mb-2 text-black">
						<div>
              ISSUE No. {issue_number?.text}
							{` `}
							<span className="font-serif text-match italic">
								{issue_title?.text}
							</span>
						</div>
					</h5>
					<h2
						className="text-xl mb-2"
          >
            <RichText
              htmlSerializer={htmlSerializer}
              render={editors_foreword?.raw}
            />
					</h2>
					<h5 className="flex items-baseline justify-end text-black uppercase">
            Editor’s Letter
					</h5>
				</div>
			</TransitionLink>
			<TableOfContents articles={articles} />
		</div>
	)
}

export default IssueArchive
export { IssueArchive }
